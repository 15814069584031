//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

// import CompanyViewToolbar from '@/modules/company/components/company-view-toolbar.vue';
import ReviewListCard from '@/shared/components/review/review-list-card.vue';

import CompanyInfo from '@/modules/company/components/partial-views/company-info.vue';
import CompanyOwnerInfo from '@/modules/company/components/partial-views/company-owner-info.vue';
import CompanyBranches from '@/modules/company/components/partial-views/company-branches.vue';
import AboutCompany from '@/modules/company/components/partial-views/about-company.vue';
import TransactionListCard from '@/modules/transaction/components/partial/transaction-list-card.vue';
import LoadingCompanyInfo from '@/modules/company/components/partial-views/loading-company-info.vue';

import vueI18n from '@/vueI18n'
import { i18n } from '@/vueI18n';
import moment from 'moment';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore'
// const db = firebase.firestore();

import Roles from '@/security/roles';
import { CompanyModel } from '@/modules/company/company-model';
const { fields } = CompanyModel;

export default {
  name: 'app-company-view-page',

  // props: ['id'],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  
  components: {
    // [CompanyViewToolbar.name]: CompanyViewToolbar,
    [ReviewListCard.name]: ReviewListCard,
    [TransactionListCard.name]: TransactionListCard,
    [LoadingCompanyInfo.name]: LoadingCompanyInfo,
    [CompanyInfo.name]: CompanyInfo,
    [CompanyOwnerInfo.name]: CompanyOwnerInfo,
    [CompanyBranches.name]: CompanyBranches,
    [AboutCompany.name]: AboutCompany,
  },
  
  async mounted() {
    await this.doFindByVuexfire(this.id);
  },

  data() {
    return {
      model: null,
      totalRating: 0,
      totalAmount: 0,
      companyName: undefined,
      commercialRegister: undefined,
      license: undefined,
      description: undefined,
    };
  },
  computed: {
    ...mapGetters({
      record: 'company/view/record',
      loading: 'company/view/loading',
      toggleStatusloading: 'iam/view/statusLoading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    language() {
      return vueI18n.locale
    },

  },
  // firestore() {
  //   return {
  //     record: db.collection('user').doc(this.id),
  //   }
  // },
  watch: {
    record(val) {
      if (val) {
        this.companyName = this.record.name[this.currentLanguageCode]
        this.totalRating = this.record.rate
        this.totalAmount = this.record.wallet
        this.commercialRegister = this.record.commercialRegister
        this.license = this.record.license
        this.description = this.record.description[this.currentLanguageCode]
      }
    }
  },
  methods: {
    ...mapActions({
      doFind: 'company/view/doFind',
      doFindByVuexfire: 'company/view/doFindByVuexfire',
      doToggleUserAccountStatus: 'iam/view/doToggleUserAccountStatus'
    }),
    i18n(key, args) {
      // return this.$t(key, args);
      return i18n(key, args);
    },
    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },
    presenter(fieldName) {
      const val = CompanyModel.presenter(this.record, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == this.record[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(fieldName) {
      const val = CompanyModel.presenter(this.record, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(val) {
      // const val = CompanyModel.presenter(this.record, fieldName);
      // return val ? moment(val).locale(this.currentLanguageCode).format("dddd D MMM, YYYY h:mm A") : 'ـــ'; 
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd D MMM, YYYY") + this.i18n('common.at') + moment(val).locale(this.currentLanguageCode).format("hh:mm A") : 'ـــ';
    },
    presenterDate(val) {
      // const val = CompanyModel.presenter(this.record, fieldName)
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(fieldName) {
      return moment(CompanyModel.presenter(this.record, fieldName)).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = CompanyModel.presenter(this.record, fieldName)
      let n = this.record[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterUser(fieldName, key) {
      const user = CompanyModel.presenter(this.record, fieldName);
      if (key == 'avatars' || key == 'avatar') {
        let avatar = null
        if (user && Array.isArray(user[key])) {
          avatar = user[key].length ? user[key][0].publicUrl : null
        } else if (user && user[key]) {
          avatar = user[key].publicUrl 
        }
        return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
      }
      return user && user[key] ? user[key] : 'ـــ'; 
    },
    presenterClient(fieldName, key) {
      const val = CompanyModel.presenter(this.record, fieldName);
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    presenterLocalization(data) {
      if (typeof data === 'object' && data !== null) {
        return data[this.currentLanguageCode] ? data[this.currentLanguageCode] : data['en']
        // if (this.currentLanguageCode in data) {
        //   return data[this.currentLanguageCode]
        // }
      }
      return  
    },
    toggleAccountStatus(id) {
      console.log(id);
      this.doToggleUserAccountStatus(id)
    },
  },
};
