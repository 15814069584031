import { render, staticRenderFns } from "./company-info.vue?vue&type=template&id=08b0ec2c&scoped=true&"
import script from "./company-info.vue?vue&type=script&lang=js&"
export * from "./company-info.vue?vue&type=script&lang=js&"
import style0 from "./company-info.vue?vue&type=style&index=0&id=08b0ec2c&lang=scss&scoped=true&"
import style1 from "./company-info.vue?vue&type=style&index=1&id=08b0ec2c&lang=scss&scoped=true&"
import style2 from "./company-info.vue?vue&type=style&index=2&id=08b0ec2c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b0ec2c",
  null
  
)

export default component.exports
import {QCard,QInnerLoading,QSpinnerIos,QItemSection,QAvatar,QImg,QItemLabel,QIcon,QTooltip} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QInnerLoading,QSpinnerIos,QItemSection,QAvatar,QImg,QItemLabel,QIcon,QTooltip})
