import { render, staticRenderFns } from "./company-owner-info.vue?vue&type=template&id=05e4557f&scoped=true&"
import script from "./company-owner-info.vue?vue&type=script&lang=js&"
export * from "./company-owner-info.vue?vue&type=script&lang=js&"
import style0 from "./company-owner-info.vue?vue&type=style&index=0&id=05e4557f&lang=scss&scoped=true&"
import style1 from "./company-owner-info.vue?vue&type=style&index=1&id=05e4557f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e4557f",
  null
  
)

export default component.exports
import {QCard,QItemSection,QAvatar,QImg,QItemLabel} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QItemSection,QAvatar,QImg,QItemLabel})
