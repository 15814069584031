//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import vueI18n from '@/vueI18n';
import { i18n } from '@/vueI18n';
import moment from 'moment';

export default {
  name: 'app-branch-view-modal',

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    branch: {
      type: Object,
      required: true,
    }, 
    guides: {
      type: Array,
      required: true,
    }, 
    companyName: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    language() {
      return vueI18n.locale
    },
  },
  methods: {
    i18n(code, args) {
      return i18n(code, args);
    },
    doClose() {
      this.$emit('close')
    },
    async doSubmit() {
      await this.$emit('submit', this.id)
    },
    presenterDateTime(date) {
      // const val = OfferModel.presenter(row, fieldName)
      return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY hh:mm a") : 'ـــــــ'; 
    },
    presenterLocalization(data) {
      if (typeof data === 'object' && data !== null) {
        return data[this.currentLanguageCode] ? data[this.currentLanguageCode] : data['en']
        // if (this.currentLanguageCode in data) {
        //   return data[this.currentLanguageCode]
        // }
      }
      return  
    },
  },
};
