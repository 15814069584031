import { render, staticRenderFns } from "./about-company.vue?vue&type=template&id=2fc4953a&scoped=true&"
import script from "./about-company.vue?vue&type=script&lang=js&"
export * from "./about-company.vue?vue&type=script&lang=js&"
import style0 from "./about-company.vue?vue&type=style&index=0&id=2fc4953a&lang=scss&scoped=true&"
import style1 from "./about-company.vue?vue&type=style&index=1&id=2fc4953a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc4953a",
  null
  
)

export default component.exports
import {QSkeleton} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSkeleton})
