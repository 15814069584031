import { render, staticRenderFns } from "./company-view-page.vue?vue&type=template&id=a56f4f62&scoped=true&"
import script from "./company-view-page.vue?vue&type=script&lang=js&"
export * from "./company-view-page.vue?vue&type=script&lang=js&"
import style0 from "./company-view-page.vue?vue&type=style&index=0&id=a56f4f62&lang=scss&scoped=true&"
import style1 from "./company-view-page.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a56f4f62",
  null
  
)

export default component.exports
import {QBreadcrumbs,QIcon,QBreadcrumbsEl,QCard,QItemSection,QItemLabel,QImg} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBreadcrumbs,QIcon,QBreadcrumbsEl,QCard,QItemSection,QItemLabel,QImg})
