//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';
import moment from 'moment';

import BranchViewModal from '@/modules/company/components/partial-views/branch-view-modal.vue';

// import { ReviewsModel } from '@/modules/branchs/branchs-model';
// import ReviewsViewModal from '@/modules/branchs/components/branchs-view-modal.vue';

// import { ReviewsPermissions } from '@/modules/branchs/branchs-permissions';
// import { FormSchema } from '@/shared/form/form-schema';
// const { fields } = ReviewsModel;

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

export default {
  name: 'app-branch-card',

  props: {
    value: {
      type: Object,
      required: true,
    }, 
    companyName: {
      type: String,
      required: false,
    },
  },

  components: {
    [BranchViewModal.name]: BranchViewModal,
  },

  firestore() {
    return {
      guides: firebase.firestore().collection('user').where('branchId', '==', this.value.id).get(),
    }
  },
  data() {
    return {
      dialogVisible: false,
      modalVisible: false,
      model: null,
      guides: [],
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
  methods: {
    ...mapActions({

    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    presenterDate(val) {
      return moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
    },
    onOpenModal() {
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
  },
};
