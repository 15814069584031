//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadingBranchCard from '@/modules/company/components/partial-views/loading-branch-card.vue';
import BranchCard from '@/modules/company/components/partial-views/branch-card.vue';

import { mapGetters, mapActions } from 'vuex';
import vueI18n from '@/vueI18n'
import { i18n } from '@/vueI18n';
// import moment from 'moment';

import { UserModel } from '@/modules/auth/user-model';
const { fields } = UserModel;


// import { Swiper, SwiperSlide } from 'swiper/vue';   // Import Swiper Vue.js components
// import 'swiper/swiper.scss';    // Import Swiper styles

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
// or
// import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
// or
// import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

export default {
  name: 'app-company-branches',
  
  props: {
    companyId: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: false,
    },
  },

  components: {
    [BranchCard.name]: BranchCard,
    [LoadingBranchCard.name]: LoadingBranchCard,
    // Swiper,
    // SwiperSlide,
    Splide,
    SplideSlide,
  },

  async mounted() {
    await this.doFetchBranchesByVuexfire(this.companyId);
  },

  data() {
    return {
      model: null,
      slide: 1,
      
    };
  },
  computed: {
    ...mapGetters({
      rows: 'company/list/branches',
      loading: 'company/list/loadingBranches',

      screen_size: 'layout/screen_size',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    language() {
      return vueI18n.locale
    },
    options() {
      let opt = {
        rewind: true,
        // autoWidth: true,
        // perPage: 2,
        gap: '1rem',
        arrows: false,
      }
      if (this.is_screen_xs) {
        opt['perPage'] = 1
      } else if (this.is_screen_sm) {
        opt['perPage'] = 2
      } else {
        opt['perPage'] = 3
      }
      return opt
    },
  },
  watch: {
    'screen_size.width'(val) {
      console.log('Screen Width = ', val);
      
    }
  },
  methods: {
    ...mapActions({
      doFetchBranchesByVuexfire: 'company/list/doFetchBranchesByVuexfire'
    }),
    i18n(key, args) {
      // return this.$t(key, args);
      return i18n(key, args);
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log('slide change');
    },
  },

};
