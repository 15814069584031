//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import vueI18n from '@/vueI18n'
import { i18n } from '@/vueI18n';
// import moment from 'moment';

import BranchCard from '@/modules/company/components/partial-views/branch-card.vue';

export default {
  name: 'app-about-company',
  
  props: {
    companyName: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
  },

  components: {
    [BranchCard.name]: BranchCard,
  },

  data() {
    return {
      model: null,
      slide: 1,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'company/view/loading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    language() {
      return vueI18n.locale
    },
  },
  methods: {
    ...mapActions({
    
    }),
    i18n(key, args) {
      // return this.$t(key, args);
      return i18n(key, args);
    },

  },

};
